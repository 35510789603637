<template>
    <div>
        <StackRouterHeaderBar :title="`구체적 장소`" :showTitle="true" />
        <div class="input-wrapper items-center">
            <i class="material-icons i-search">search</i>
            <form style="width: 100%" action=".">
                <input ref="input" type="search" v-model="searchValue" :placeholder="placeholder" />
            </form>
        </div>
        <div ref="map" class="map-container init"></div>
        <div class="search-result">
            <div class="result" @click="sendPosition(result)" v-for="(result, idx) in searchResults" :key="result.id">
                <div class="place-name" v-html="`${alphabet[idx]}. ${result.place_name}`" />
                <div class="place-address" v-html="result.address_name" />
            </div>
        </div>
    </div>
</template>

<script>
import StackRouterHeaderBar from '@/components/app/StackRouterHeaderBar'
export default {
    name: 'KaKaoMapPage',
    components: {
        StackRouterHeaderBar,
    },
    data: () => ({
        map: null,
        defaultPosition: [127.033642374422, 37.5010572840691, 4], // [x좌표, y좌표, 줌 레벨]
        markers: [],
        searchValue: null,
        searchResults: [],
    }),
    computed: {
        alphabet() {
            return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O']
        },
        placeholder() {
            return '구체적인 장소를 입력해주세요'
        },
        prevMeetPlace() {
            return this.$store.getters.meetPlace
        },
    },
    beforeDestroy() {
        this.map = null
    },
    mounted() {
        const input = this.$refs.input
        input.addEventListener('keydown', e => {
            if (e.keyCode === 13) {
                e.preventDefault()
                this.search()
            }
        })

        this.init(this.defaultPosition)
    },
    methods: {
        init(arr) {
            const mapContainer = this.$refs.map
            const mapOption = {
                center: new kakao.maps.LatLng(arr[1], arr[0]),
                level: arr[2],
            }
            this.map = new kakao.maps.Map(mapContainer, mapOption)
        },
        shortMapHeight() {
            const mapContainer = this.$refs.map
            if (mapContainer.classList.contains('init')) {
                mapContainer.classList.remove('init')
                this.map.relayout()
            }
        },
        drawMarkers() {
            // 새로 검색 시 기존 마커 찍어놓은거 초기화
            if (this.markers.length) {
                this.markers.forEach(marker => marker.setMap(null))
                this.markers = []
            }

            const bounds = new kakao.maps.LatLngBounds()
            const markers = []

            this.searchResults.forEach(res => {
                const latlng = new kakao.maps.LatLng(res.y, res.x)
                bounds.extend(latlng)

                markers.push({
                    title: res.place_name,
                    latlng: latlng,
                })
            })
            const img = require('@/assets/images/social-meeting/pin.svg')
            markers.forEach((m, idx) => {
                const content = `
                    <div style="position: relative">
                        <img style="width: 30px; height: 30px" src="${img}"/>
                        <span class="f-bold f-14" style="color: white; position: absolute; top: 0; left: 10px;">
                        ${this.alphabet[idx]}
                        </span>
                    </div>
                    `
                const overlay = new kakao.maps.CustomOverlay({
                    position: m.latlng,
                    content: content,
                })
                this.markers.push(overlay)
                overlay.setMap(this.map)
            })

            this.map.setBounds(bounds)
        },
        search() {
            if (this.searchValue) {
                const ps = new kakao.maps.services.Places()
                var callback = (result, status) => {
                    if (status === kakao.maps.services.Status.OK) {
                        this.shortMapHeight()

                        this.searchResults = result
                        this.drawMarkers()
                    } else if (status === 'ZERO_RESULT') {
                        this.$toast.error('검색 결과가 없어요')
                    } else {
                        this.$toast.error('오류가 발생했어요')
                    }
                }
                ps.keywordSearch(this.searchValue, callback)
            }
        },
        sendPosition(result) {
            if (this.prevMeetPlace) {
                this.$store.commit('setMeetPlace', null)
            }
            this.$store.commit('setMeetPlace', result)
            this.$stackRouter.pop()
        },
    },
}
</script>

<style scoped lang="scss">
.input-wrapper {
    display: flex;
    flex-direction: row;
    width: calc(100% - 40px);
    height: 52px;
    border-radius: 8px;
    border: 1px solid $grey-02;
    padding: 12px 16px;
    margin: 32px 12px 12px 12px;

    .material-icons {
        font-size: $icon-size;
        color: $grey-08;
        margin-right: 8px;
    }

    input {
        font-size: 15px;
        margin: 0;
        padding: 0;
        color: black;
    }

    input::placeholder {
        color: $grey-05;
    }
}
.map-container {
    width: 100%;
    height: 320px;

    &.init {
        height: 100%;
    }
}
.search-result {
    height: calc(100vh - 52px - 96px - 320px);
    overflow-y: auto;

    .result {
        padding: 16px;
        border-bottom: 1px solid $grey-01;

        .place-name {
            color: $blue-facebook;
            font-size: 16px;
            @include f-medium;
        }
    }
}
</style>
